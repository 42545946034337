@import "../../styles/variables";

#formReview_Switch {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  height: 1.3rem;
  padding: 1.18rem 0 1.18rem 0.8rem;
  border-radius: 4px;
  border: 1px solid $hard_grey;
  font-weight: 200;

  .formReview_Switch_Label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
  }
}

.autoComplete {
  margin-top: 8px;
  margin-bottom: 16px;
  height: 42px !important;

  .textField_dropdown > div {
    padding: 4px !important;
  }

  //Text is not set in the middle of dropdown. This will make animation to dissapear
  /* .textField_dropdown > label {
    transform: translate(14px, 16px) scale(1) !important;
  } */
}

.deploymentTarget {
  display: flex;
  flex-shrink: 2;

  .approvedDeploymentTarget {
    width: 100%;
  }
}
