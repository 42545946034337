@import "../../styles/variables";

.createReview__wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  height: 100%;

  .createReview_message {
    display: flex;
    padding: 2rem 0;
  }

  .createReview_title {
    font-weight: 700;
    font-size: 2.25rem;
    line-height: 3.375rem;
  }

  .createReview_card_main {
    display: flex;
    margin: 3rem 0rem 3rem;

    .createReview_card_single {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border-radius: 1rem;
      padding: 1rem;
      border: 2px solid rgba($blue, 0.2);
      box-shadow: 0 0.125rem 1rem rgba($blue, 0.25);
      min-height: 4rem;
      margin-right: 20px;
      cursor: pointer;
      width: 12rem;
      text-align: center;
    }

    .createReview_card_selected {
      border: 2px solid $green;
    }

    .createReview_card_review {
      font-size: 1rem;
      font-weight: bold;
    }
  }

  .createReview_form {
    line-height: 4rem;
    height: 100%;
    width: 60%;
  }

  .createReview_container_button {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;

    .createReview_button {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 0.8rem;
      margin-top: 1rem;
      border: none;
      border-radius: 1rem;
      width: 7rem;
      height: 3rem;
      color: $grey;
    }

    .createReview_button_submit {
      background-color: $darker_green;
    }

    .createReview_button_cancel {
      background-color: $blue;
    }
  }

  .createReview_message_form {
    display: flex;
    padding: 2rem 0;
  }

  .createReview_message_submit {
    display: flex;    
    padding: 2rem 0;
  }

  .createReview_message_submit_inner {
    display: flex;
    flex-direction: column;
  }

  .link_refresh {
    color: $black;
    text-decoration: none;
    outline: none;
    align-self: flex-end;
    margin-top: 1rem;
  }
}
